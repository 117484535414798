import React, { useState, useRef } from "react";

const getDefaultValues = () => ({
  socialSecurityNumber: "",
  address: "",
  validityDate: new Date().toLocaleDateString("sv-FI"),
});

const Form = ({ onSubmit,onCancel, loading }) => {
  const personidEl = useRef(null);

  const [data, setData] = useState(getDefaultValues());

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({...data});
  };

  const handleCancel = () => {
    setData(getDefaultValues());
    onCancel();
    personidEl.current.focus();
  };

  const handleInput = (name) => (e) => {
    setData({...data, [name]: e.target.value});
  };

  return (
      <form className="m-3 input-form" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="socialSecurityNumber" className="form-label">
            Henkilötunnus
            <span id="personidHelp" className="form-text mx-2">
            (Person ID)
          </span>
          </label>
          <input
              type="text"
              disabled={loading}
              className="form-control"
              id="socialSecurityNumber"
              aria-describedby="personidHelp"
              value={data.socialSecurityNumber}
              onChange={handleInput("socialSecurityNumber")}
              required
              autoFocus
              ref={personidEl}
          />

        </div>

        <div className="mb-3">
          <label htmlFor="address" className="form-label">
            Osoite
            <span id="addressHelp" className="form-text mx-2">
            (Address)
          </span>
          </label>
          <input
              type="text"
              className="form-control"
              id="address"
              disabled={loading}
              aria-describedby="addressHelp"
              value={data.address}
              onChange={handleInput("address")}
              required
          />

        </div>

        <div className="mb-3">
          <label htmlFor="validityDate" className="form-label">
            Päiväys
            <span id="dateHelp" className="form-text mx-2">
            (Date)
          </span>
          </label>
          <input
              type="date"
              disabled={loading}
              className="form-control"
              id="validityDate"
              aria-describedby="dateHelp"
              value={data.validityDate}
              onChange={handleInput("validityDate")}
              required
          />

        </div>
        <div className="d-flex justify-content-start">
          <button
              type="submit"
              className="btn btn-primary me-3"
              title="Get info"
              disabled={loading}
          >
            Hae tiedot
            <span id="submitButtonHelp" style={{color:'#dddddd'}} className="form-text mx-2">
            (Submit)
          </span>
            <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                style={loading ? {visibility: "visible", marginLeft: 8} : {visibility: "hidden"}}
            />
          </button>
          <button
              type="button"
              className="btn btn-secondary me-3"
              title="Clear info"
              disabled={loading}
              onClick={handleCancel}
          >
            Tyhjennä tiedot
            <span id="cancelButtonHelp" style={{color:'#dddddd'}} className="form-text mx-2">
            (Clear)
          </span>
          </button>
        </div>
      </form>
  );
};

export default Form;
