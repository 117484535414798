import React, { useState, useMemo } from "react";
import logo from "./assets/logo.png";
import "./styles.css";
import Form from "./Form";
import Output from "./Output";
import { InteractionType } from "@azure/msal-browser";
import { useMsal, useAccount, useIsAuthenticated, MsalAuthenticationTemplate } from "@azure/msal-react";
import { getHomeInsuranceAddresses } from './api/getHomeInsuranceAddresses';
import {Button, Menu, MenuItem} from "@material-ui/core";

const ErrorComponent = ({ error }) => {
  return <div className="m-4">
    <h4>An error occurred: {error.errorMessage}</h4>
    <div>Refresh the page to retry the login attempt</div>
  </div>;
};

const Loading = () => {
  return <div className="m-4">Authentication in progress...</div>
};

const shapeResponse = (response) => {
  if (response === null
      || response.getHomeInsuranceAddressesResponse === null
      || response.getHomeInsuranceAddressesResponse.Addresses === null
      || response.getHomeInsuranceAddressesResponse.Addresses.length === 0) {
    //TODO: if the Array is empty, should we display something like "no data was found"?
    return [];
  } else {
    const allAdresses = response.getHomeInsuranceAddressesResponse.Addresses;
    // allAdresses.push(allAdresses[0]);
    return allAdresses
  }
}

const App = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const {instance, accounts} = useMsal();
  const account = useAccount(accounts[0] || {});
  const isAuthenticated = useIsAuthenticated();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleSubmit = async (data) => {
    setError(null);
    setLoading(true);
    setResponse(null);

    try {
      const tokenResp = await instance.acquireTokenSilent({account: account});
      const body = {
        getHomeInsuranceAddresses: {
          ...data
        }
      };
      const dataResp = await getHomeInsuranceAddresses(body, tokenResp.idToken);
      //TODO: validate that the response has getHomeInsuranceAddressesResponse and the array of Addresses
      setResponse(dataResp);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setError(null);
    setResponse(null);
  }

  const handleLogout = () => instance.logout()


  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const responseShaped = useMemo(() => shapeResponse(response), [response]);

  return <>
    <header className="header">
      <img className="logo" src={logo} alt="Vakuutustiedot (Insurance information)"></img>
      <h1>Vakuutustiedot <span style={{fontSize:17}}>(Insurance information)</span> </h1>
      {isAuthenticated &&
      <>
        <Button style={{textTransform: 'none'}} disableRipple className="ms-auto d-none d-sm-block mx-3"
                onClick={handleOpenMenu}>{account?.name}</Button>
        <Menu
            id="account-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleLogout}>
            <div style={{alignItems:'center'}}>
              Kirjautua ulos
              <span id="logoutButtonHelp"  className="form-text mx-2">
                  (Logout)
              </span>
            </div>
          </MenuItem>
        </Menu>
      </>
      }

    </header>
    <main>
      <div className="container-sm">
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}>
          <Form onSubmit={handleSubmit} onCancel={handleCancel} loading={loading}/>
          {(response || error) && <Output value={responseShaped} error={error}/>}
        </MsalAuthenticationTemplate>
      </div>
    </main>
  </>
}

export default App;
