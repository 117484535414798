import { apiUrl } from '../configuration';

export async function getHomeInsuranceAddresses(body, idToken) {
    const options = {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': `Bearer ${idToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
 
    return new Promise((resolve, reject) => {
        fetch(apiUrl, options)
            .then(response => {
                if (response.ok) {
                    resolve(response.json());
                }
                else {
                    const { status, statusText } = response;
                    response.json().then(error => reject({ status, statusText, error }))
                }
            })
            .catch(error => reject(error))
    });
}