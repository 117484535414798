export const msalConfig = {
  //TODO: replace clientId and tenantId (in authority) with the values from Recover Nordic AD
  //TODO: set up redirectUri in app regisrtation in Recover Nordic
  auth: {
    clientId: "527fd086-2cdc-43dd-a766-b904ccbe7be1",
    authority:
      "https://login.microsoftonline.com/3eda027e-8a74-4cb5-96ca-7586478e54fc",
    redirectUri: "https://ccfi.recgrp.io/",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        //console.log(level, message);
      },
    },
  },
};

// /insurance/GetHomeInsuranceAddresses   
export const apiUrl = "insurance/GetHomeInsuranceAddresses";