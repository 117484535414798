import MaterialTable from "material-table";
import {materialTableIcons} from "./assets/materialTableIcons";
import {Divider} from "@material-ui/core";

const Output = ({ value=[], error }) => {

    const cellDefaultStyle ={
        borderRight: '1px solid #8a8a8a',
    }

    const getColumns = () => [
        {title: 'Osoite (Address)', field: 'addressText', cellStyle : cellDefaultStyle},
        {title: 'Yhtiö (Company)', field: 'companyName', cellStyle : cellDefaultStyle},
        {title: 'Soveltamisala (Scope)', field: 'scopeOfInsurancePolicyDescription', cellStyle : cellDefaultStyle},
    ]

    return (
        <div className="mx-3 my-5">
            <Divider style={{backgroundColor:'black', marginBottom:15}}/>
            <label htmlFor="Addresses" className="form-label">
                Tiedot
                <span id="AddressesHelp" className="form-text mx-2">
                    (Information)
                </span>
            </label>
            {!error
                ?
                <MaterialTable
                    icons={materialTableIcons}
                    options={
                        {
                            toolbar: false,
                            sorting: true,
                            filtering: false,
                            paging: false,
                            rowStyle:  (rowData, index) => {
                                return index % 2 ? {backgroundColor: "#ffffff"} : {backgroundColor: "#f8f8f8"};
                            },
                            headerStyle:{
                                backgroundColor: '#c1c1c1',
                                color: 'white',
                                borderRight: '1px solid black'
                            }
                        }
                    }
                    columns={getColumns()} data={value}/>
                :
                <textarea
                    className={`form-control output error`}
                    id="Addresses"
                    rows="3"
                    aria-describedby="AddressesHelp"
                    value={error.statusText}
                    readOnly
                />
            }
        </div>
    );
}

export default Output;
